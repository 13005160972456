/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


@import "~bootstrap/scss/functions";

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";

@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";



.mat-row {

    &.row-red {
        .mat-cell {
            color: red !important;
        }

    }

    &.row-green {
        .mat-cell {
            color: green !important;
        }
    }
}

.info-container {
    max-height: calc(100vh - 250px);
    overflow: auto;
}

.limit-form-field {
    position: absolute !important;
    top: 8px;
    right: 8px;
}